import gql from "graphql-tag";
import {Query} from "@deity/falcon-ecommerce-uikit";

export const GET_LAST_ORDER = gql`
  query LastOrder {
    lastOrder {
      entityId
      incrementId
      createdAt
      customerFirstname
      customerLastname
      status
      subtotal
      shippingAmount
      shippingInclTax
      subtotalInclTax
      grandTotal
      taxAmount
      orderCurrencyCode
      shippingDescription
      paymentMethodName
      payment {
        method
        ccLast4
        ccType
      }
      items {
        itemId
        qty
        sku
        name
        rowTotalInclTax
        thumbnailUrl
        link
        manufacturer
        productId
        options {
          label
          value
        }
      }
      billingAddress {
        company
        firstname
        lastname
        street
        city
        postcode
        countryId
        telephone
      }
      shippingAddress {
        company
        firstname
        lastname
        street
        city
        postcode
        countryId
        telephone
      }
      invoices {
        url
        id
      }
      shipments {
        trackNumber
        items {
          itemId
        }
      }
    }
  }
`;

export class LastOrderQuery extends Query {
  static defaultProps = {
    query: GET_LAST_ORDER
  };
}
